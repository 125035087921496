import React, { useRef } from "react";
import { Row, Col, message } from "antd";
import {
  CalendarOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import ProgressBar from "../../components/ProgressBar";
import Wrapper from "../../components/Wrapper";
import { useRecoilState } from "recoil";
import { userAtom } from "../../Recoil/Atoms/user";
import { registerWithEmail } from "../../Actions/FirebaseAction";
import { FirebasePassword } from "../../config";
// assets;

// toastr
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const SelectGender = (props) => {
  // const [selected, setSelected] = useState([]);
  // const [update, setupdater] = useState(false);
  const [userData, setUserData] = useRecoilState(userAtom);
  const ageRef = useRef(null);
  const nameRef = useRef(null);
  const postalRef = useRef(null);
  const cityRef = useRef(null);
  const ftRef = useRef(null);
  const inRef = useRef(null);
  const phRef = useRef(null);
  const emailRef = useRef(null);
  const currentWeightRef = useRef(null);
  const TargetWeightRef = useRef(null);

  const formValidation = async () => {
    console.log("here at build version  1.0.2");
    console.log("height", ftRef.current.value, inRef.current.value);
    // console.log("email >>>>", emailRef.current.value);
    try {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // console.log(isNaN(ageRef.current.value));
      if (nameRef.current.value < 1) {
        message.error("Please enter your Full Name ");
        return;
      }
      if (cityRef.current.value < 1) {
        message.error("Please enter your City Name");
        return;
      }
      if (postalRef.current.value < 1) {
        message.error("Please enter your Postal Code ");
        return;
      }
      if (isNaN(ageRef.current.value) || ageRef.current.value.length < 1) {
        message.error("Please enter valid age");
        return;
      }
      if (
        ftRef.current.value.length < 1 ||
        inRef.current.value.length < 1 ||
        Number(ftRef.current.value > 8) ||
        Number(inRef.current.value) > 12
      ) {
        console.log("value", ftRef.current.value);
        console.log("value", inRef.current.value);
        message.error("Please enter valid Height should be under 8ft 12in ");
        return;
      }
      if (isNaN(currentWeightRef.current.value)) {
        message.error("Please enter valid current weight");
        return;
      }
      if (isNaN(TargetWeightRef.current.value)) {
        message.error("Please enter valid target weight");
        return;
      }
      if (isNaN(phRef.current.value || phRef.current.value.length !== 10)) {
        message.error("Please enter valid phone number");
        return;
      }
      if (!re.test(String(emailRef.current.value).toLowerCase())) {
        message.error("Please enter valid Email address");
        return;
      }

      // let response = await setUserData({
      //   ...userData,
      //   age: ageRef.current.value,
      //   targetWeight: TargetWeightRef.current.value,
      //   currentWeight: currentWeightRef.current.value,
      //   phone: phRef.current.value,
      //   email: emailRef.current.value,
      //   height: { ft: ftRef.current.value, in: inRef.current.value },
      // });

      let responseFirebase = await registerWithEmail({
        email: emailRef.current.value,
        password: FirebasePassword,
        userInfo: {
          ...userData,
          age: ageRef.current.value,
          targetWeight: TargetWeightRef.current.value,
          currentWeight: currentWeightRef.current.value,
          phone: phRef.current.value,
          email: emailRef.current.value,
          height: { ft: ftRef.current.value, in: inRef.current.value },
          isPaid: false,
        },
      });
      // console.log('responseFirebase', responseFirebase);
      if (responseFirebase.success) {
        toastr.success(responseFirebase.message);
        props.history.push(`/DB/${responseFirebase.userID}`);
      } else {
        toastr.error(responseFirebase.message);
      }
    } catch (err) {
      toastr.error("Server Error: Try again later!");
    }
  };
  return (
    <Wrapper>
      <Row className={"select_row"} align={"top"} justify="start">
        <Col align="left" span={8} xs={24} md={8}>
          <div className="blockname">
            <div style={{ marginLeft: "10rem" }} className="blocknametext">
              Your
            </div>
            <div style={{ marginLeft: "8rem" }} className="blocknametext">
              Profile
            </div>
          </div>
        </Col>
        <Col align="center" span={8} xs={24} md={8}>
          <Row
            style={{ marginTop: "3rem" }}
            gutter={10}
            align="middle"
            justify="center"
          >
            <Col className="background--grey">
              <h4 className="heading">Important Details</h4>
            </Col>
            {/* <Col>
              <Wrong className="icon-change" />
            </Col> */}
          </Row>
          <Row align="middle" justify="center">
            <h5 className="sub_heading background--grey">
              These Details will help us to create accurate and custom diet plan
              for you.
            </h5>
          </Row>
          <Row
            className="magicBorders"
            align="middle"
            justify="start"
            gutter={30}
          >
            <Col align="start" span={4}>
              <UserOutlined style={{ color: "#606469", fontSize: "4rem" }} />
            </Col>
            <Col span={20}>
              <input
                ref={nameRef}
                placeholder="Full Name"
                className="magic__inputs"
              />
            </Col>
          </Row>
          <Row
            className="magicBorders"
            style={{ marginTop: "3rem" }}
            align="middle"
            justify="start"
            gutter={30}
          >
            <Col align="start" span={4}>
              <CalendarOutlined
                style={{ color: "#606469", fontSize: "4rem" }}
              />
            </Col>
            <Col span={20}>
              <input
                ref={ageRef}
                pattern="[0-9]*"
                placeholder="AGE"
                className="magic__inputs"
              />
            </Col>
          </Row>
          <Row
            gutter={30}
            style={{ marginTop: "3rem" }}
            align="middle"
            justify="start"
          >
            <Col span={12}>
              <Row className="magicBorders" align="middle" gutter={30}>
                <Col align="start" span={8}>
                  <UserOutlined
                    style={{ color: "#606469", fontSize: "4rem" }}
                  />
                </Col>
                <Col span={16}>
                  <input
                    ref={ftRef}
                    pattern="[0-9]*"
                    placeholder="FT"
                    className="magic__inputs"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row
                className="magicBorders"
                align="middle"
                justify="start"
                gutter={30}
              >
                <Col align="start" span={8}>
                  <UserOutlined
                    style={{ color: "#606469", fontSize: "4rem" }}
                  />
                </Col>
                <Col span={16}>
                  <input
                    ref={inRef}
                    pattern="[0-9]*"
                    placeholder="Inch"
                    className="magic__inputs"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{ marginTop: "3rem" }}
            className="magicBorders"
            align="middle"
            justify="start"
            gutter={30}
          >
            <Col align="start" span={4}>
              <InfoCircleOutlined
                style={{ color: "#606469", fontSize: "4rem" }}
              />
            </Col>
            <Col span={20}>
              <input
                ref={currentWeightRef}
                pattern="[0-9]*"
                placeholder="Current Weight(kg)"
                className="magic__inputs"
              />
            </Col>
          </Row>
          <Row
            style={{ marginTop: "3rem" }}
            className="magicBorders"
            align="middle"
            justify="start"
            gutter={30}
          >
            <Col align="start" span={4}>
              <InfoCircleOutlined
                style={{ color: "#606469", fontSize: "4rem" }}
              />
            </Col>
            <Col span={20}>
              <input
                ref={TargetWeightRef}
                pattern="[0-9]*"
                placeholder="Targeted Weight(kg)"
                className="magic__inputs"
              />
            </Col>
          </Row>
          <Row
            style={{ marginTop: "3rem" }}
            className="magicBorders"
            align="middle"
            justify="start"
            gutter={30}
          >
            <Col align="start" span={4}>
              <InfoCircleOutlined
                style={{ color: "#606469", fontSize: "4rem" }}
              />
            </Col>
            <Col span={20}>
              <input
                ref={phRef}
                pattern="[0-9]*"
                placeholder="Phone Number"
                className="magic__inputs"
              />
            </Col>
          </Row>
          <Row
            style={{ marginTop: "3rem" }}
            className="magicBorders"
            align="middle"
            justify="start"
            gutter={30}
          >
            <Col align="start" span={4}>
              <InfoCircleOutlined
                style={{ color: "#606469", fontSize: "4rem" }}
              />
            </Col>
            <Col span={20}>
              <input
                ref={postalRef}
                placeholder="Postal Code"
                className="magic__inputs"
              />
            </Col>
          </Row>
          <Row
            style={{ marginTop: "3rem" }}
            className="magicBorders"
            align="middle"
            justify="start"
            gutter={30}
          >
            <Col align="start" span={4}>
              <InfoCircleOutlined
                style={{ color: "#606469", fontSize: "4rem" }}
              />
            </Col>
            <Col span={20}>
              <input
                ref={cityRef}
                placeholder="City"
                className="magic__inputs"
              />
            </Col>
          </Row>

          <Row
            style={{ marginTop: "3rem" }}
            className="magicBorders"
            align="middle"
            justify="start"
            gutter={30}
          >
            <Col align="start" span={4}>
              <InfoCircleOutlined
                style={{ color: "#606469", fontSize: "4rem" }}
              />
            </Col>
            <Col span={20}>
              <input
                ref={emailRef}
                placeholder="Email"
                className="magic__inputs"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "2rem" }} align="bottom" justify="center">
        <h6 style={{ color: "#fff" }}>
          By clicking on Continue you agree all the{" "}
          <span style={{ color: "#11c7ff", cursor: "pointer" }}>
            terms and condition
          </span>{" "}
          of MYDietKart{" "}
        </h6>
      </Row>
      <Row align="middle" justify="center">
        <div onClick={() => formValidation()} className="rowlabel">
          Continue
        </div>
      </Row>
      <ProgressBar value={100} />
    </Wrapper>
  );
};

export default SelectGender;
