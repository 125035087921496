import React from "react";
import { Row, Col, Card } from "antd";
// loader
import Loader from "react-loaders";
import { AllCarbsData, WithoutFruitsCarbsData } from "../CarbsData/CarbsData";
import ColumnItemList from "../ColumnItemList/ColumnItemList";

const Loading = () => (
  <Loader type="ball-pulse" className="animated fadeIn pt-3 text-center" />
);

const DietTable = ({
  userProtien,
  userCarbs,
  userFat,
  dietChart,
  day,
  week,
  userInfo,
  summary,
}) => {
  const [protiensData, setProtiensData] = React.useState([]);
  const [carbsData, setCarbsData] = React.useState([]);
  const [fatsData, setFatsData] = React.useState([]);
  const [selectDay, setSelectDay] = React.useState(null);
  const [selectWeek, setSelectWeek] = React.useState(null);
  console.log("userInform from diet Table", userInfo, summary);
  console.log("userProtien", userProtien);
  // console.log("userCarbs", userCarbs);
  // console.log("userFat", userFat);
  // console.log("diet chart", dietChart)

  var perDietProtien = dietChart && dietChart.PROTIEN / 4;
  var perDietCarbs = dietChart && dietChart.CARBS / 4;
  var perDietFats = dietChart && dietChart.FAT / 4;
  // console.log("perDietProtien", perDietProtien);
  // console.log("perDietCarbs", perDietCarbs)
  // console.log("perDietFats",perDietFats)

  // Find the first digit
  function firstDigit(n) {
    // Remove last digit from number
    // till only one digit is left
    while (n >= 10) n /= 10;

    // return the first digit
    return Math.floor(n);
  }
  const unitCal = (value, unit) => {
    console.log("value", value);
    console.log("unit", unit);

    let decimalPart = parseInt((Number(value / 50) + "").split(".")[1]);
    decimalPart =
      decimalPart && decimalPart !== NaN ? firstDigit(decimalPart) : 0; // getting 1st digit
    // console.log('unitcal', value, unit, Number(value / 50), Math.trunc(Number(value / 50)), decimalPart)
    // console.log("this is the decimal ", decimalPart);
    let finalUnit;
    if (unit == "gm") {
      finalUnit =
        Math.trunc(Number(value / 50)) < 1
          ? `1/2 Bowl`
          : decimalPart >= 5 && decimalPart < 7
          ? `${Math.trunc(Number(value / 50)) + 0.5} Bowl`
          : decimalPart >= 7
          ? `${Math.trunc(Number(value / 50)) + 1}Bowl`
          : `${Math.trunc(Number(value / 50))} Bowl`;
    } else if (unit == "ml") {
      finalUnit =
        Math.trunc(Number(value / 50)) < 1
          ? `1/2 Glass`
          : //   : `${Math.trunc(Number(value / 250))} Glass`;
            "1 Glass";
    } else {
      finalUnit = `${Math.trunc(Number(value))} Unit`;
    }
    return finalUnit;
  };

  const protienCal = () => {
    let protiensArray = [];
    userProtien &&
      userProtien.map((item, i) => {
        let x = Object.values(item)[0];
        let serveQty =
          parseFloat(perDietProtien) /
          parseFloat(x["PROTIENCONTENT"] || parseFloat(x["PROTIENTCONTENT"]));
        protiensArray.push({
          item: Object.keys(item)[0],
          value: unitCal(
            Math.trunc(Number(x["QUANTITY"]) * serveQty),
            x["UNIT"]
          ),
        });
      });
    // console.log('protiensArray', protiensArray)
    setProtiensData(protiensArray);
  };

  const carbsCal = () => {
    let carbsArray = [];
    // console.log("this is useCarbs", userCarbs);
    userCarbs &&
      userCarbs.map((item, i) => {
        // console.log("this is item", item);
        let x = Object.values(item)[0];
        console.log("per diet cars", perDietCarbs);
        let perDietCalCarbs = perDietCarbs;
        if (Number(summary.targetWeight) > Number(userInfo.currentWeight)) {
          let calWeight =
            userInfo.currentWeight <= 60
              ? 17.5
              : userInfo.currentWeight <= 70
              ? 20
              : 25;

          perDietCalCarbs = perDietCalCarbs + calWeight;
        }
        console.log("per diet cars cab", perDietCalCarbs);
        let serveQty =
          parseFloat(perDietCalCarbs) / parseFloat(x["CARBSCONTENT"]);
        // console.log("x", x, Math.trunc(Number(x["QUANTITY"])), serveQty, serveQty.toFixed(2));

        carbsArray.push({
          item: Object.keys(item)[0],
          value: unitCal(
            Math.trunc(Number(x["QUANTITY"]) * serveQty.toFixed(2)),
            x["UNIT"]
          ),
          isFruit: x["isFruit"],
        });
        // console.log('carbsArray', carbsArray)
      });
    setCarbsData(carbsArray);
  };

  const fatsCal = () => {
    let fatsArray = [];
    userFat &&
      userFat.map((item, i) => {
        // console.log("item", item);
        let x = Object.values(item)[0];
        let serveQty = parseFloat(perDietFats) / parseFloat(x["fatcontent"]);
        // console.log('fatsCal-serveQty', x['quantiy'],Number(x['quantiy']), Math.trunc(Number(x['quantiy'])), serveQty.toFixed(2))
        fatsArray.push({
          item: Object.keys(item)[0],
          value: x["isOil"]
            ? `25 ${x["unit"]}`
            : unitCal(
                Math.trunc(
                  (Number(x["quantity"]) || Number(x["quantiy"])) *
                    serveQty.toFixed(2)
                ),
                x["unit"]
              ),
          isFruit: x["isFruit"],
          isOil: x["isOil"],
        });
      });
    // console.log("these are the fatarray", fatsArray);
    setFatsData(fatsArray);
  };

  React.useEffect(() => {
    carbsCal();
    protienCal();
    fatsCal();
  }, [userProtien, userCarbs, userFat]);

  const shuffle = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  React.useEffect(() => {
    // console.log('selectDay', selectDay, day, selectWeek ,week)
    if (selectDay !== day || selectWeek !== week) {
      shuffle(protiensData);
      shuffle(carbsData);
      shuffle(fatsData);
      setProtiensData(protiensData);
      setCarbsData(carbsData);
      setFatsData(fatsData);
    }
    setSelectDay(day);
    setSelectWeek(week);
  }, [day, week]);

  // console.log('protiensData', protiensData)
  console.log("carbsData", carbsData);
  // console.log('fatsData', fatsData)

  const columns = [
    "8 AM - 10 AM",
    "12 PM - 1 PM",
    "3 PM - 5 PM",
    "7 PM - 8 PM",
  ];
  let carbsWithoutFruits = carbsData.filter((f) => !f.isFruit);
  let fatwithoutOil = fatsData.filter((f) => !f.isOil);
  let cookingMedium = fatsData.filter((f) => f.isOil);
  // console.log("cookingMedium", cookingMedium);

  return (
    <>
      <div className="o-meal__table-wrap">
        <div className="o-meal__meal-table o-meal-table">
          <Row className="o-meal-table__head">
            <Col
              span={24}
              sm={24}
              md={4}
              lg={4}
              className="o-meal-table__head-item a-meal-week"
            >
              Week {week}
            </Col>
            {columns.map((column, i) => (
              <Col
                span={24}
                sm={24}
                md={5}
                lg={5}
                className="o-meal-table__head-item  a-meal-food "
                key={i}
              >
                {column}
              </Col>
            ))}
          </Row>

          {!protiensData &&
          protiensData.length === 0 &&
          !carbsData &&
          carbsData.length === 0 ? (
            <Loading />
          ) : (
            <div className="o-meal-table__body">
              <Row className="o-meal-table__row">
                <Col
                  span={24}
                  sm={24}
                  md={4}
                  lg={4}
                  className="o-meal-day__col"
                >
                  <div className="a-meal-day">
                    <div className="a-meal-day__overlay"></div>
                    <div className="a-meal-day__content">
                      <div className="a-meal-day__day">Day {day}</div>
                    </div>
                  </div>
                </Col>

                {/* 1st column */}
                <Col
                  span={24}
                  sm={24}
                  md={5}
                  lg={5}
                  className="o-meal-table__items"
                >
                  <div className="a-meal-food-small">{columns[0]}</div>
                  <div className="o-meal-table__col">
                    <div className="m-meal-item">
                      <div className="m-meal-item__header">
                        <div className="m-meal-item__content">
                          {carbsData && (
                            <>
                              <div className="m-meal-item__title">Carbs</div>
                              <ul className="list-group ">
                                {carbsData.length > 0 &&
                                  carbsData
                                    .slice(0, 1)
                                    .map((c) => <ColumnItemList c={c} />)}
                              </ul>
                              <hr
                                style={{
                                  width: "100%",
                                  height: "2px",
                                  color: "white",
                                }}
                              />
                            </>
                          )}
                          {protiensData && (
                            <>
                              <div className="m-meal-item__title mt-2">
                                Proteins
                              </div>
                              <ul className="list-group ">
                                {protiensData.length > 0 &&
                                  protiensData
                                    .slice(0, 1)
                                    .map((c) => <ColumnItemList c={c} />)}
                              </ul>
                              <hr
                                style={{
                                  width: "100%",
                                  height: "2px",
                                  color: "white",
                                }}
                              />
                            </>
                          )}
                          {fatsData && (
                            <>
                              <div className="m-meal-item__title mt-2">
                                Fats
                              </div>
                              <ul className="list-group ">
                                {fatwithoutOil.length > 0 &&
                                  fatwithoutOil
                                    .slice(0, 1)
                                    .map((c) => <ColumnItemList c={c} />)}
                              </ul>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                {/* 2nd column */}
                <Col
                  span={24}
                  sm={24}
                  md={5}
                  lg={5}
                  className="o-meal-table__items"
                >
                  <div className="a-meal-food-small">{columns[1]}</div>
                  <div className="o-meal-table__col">
                    <div className="m-meal-item">
                      <div className="m-meal-item__header">
                        <div className="m-meal-item__content">
                          {carbsWithoutFruits && (
                            <>
                              <div className="m-meal-item__title">Carbs</div>
                              <ul className="list-group ">
                                {carbsWithoutFruits.length > 0 ? (
                                  <WithoutFruitsCarbsData
                                    carbsData={carbsWithoutFruits.slice(
                                      carbsWithoutFruits.length === 1 ? 0 : 1
                                    )}
                                    column="2"
                                  />
                                ) : (
                                  <AllCarbsData
                                    carbsData={carbsData}
                                    orLength={1}
                                  />
                                )}
                              </ul>
                              <hr
                                style={{
                                  width: "100%",
                                  height: "2px",
                                  color: "white",
                                }}
                              />
                            </>
                          )}
                          {protiensData && (
                            <>
                              <div className="m-meal-item__title mt-2">
                                Protiens
                              </div>
                              <ul className="list-group ">
                                {protiensData.length > 0 &&
                                  protiensData
                                    .slice(protiensData.length === 1 ? 0 : 1)
                                    .map((c, i) => (
                                      <>
                                        <ColumnItemList c={c} />
                                        {protiensData.length -
                                          (protiensData.length === 1
                                            ? 1
                                            : 2) !==
                                          i && (
                                          <li className="list-group-item meal-item__list text-center bold">
                                            OR
                                          </li>
                                        )}
                                      </>
                                    ))}
                              </ul>
                              <hr
                                style={{
                                  width: "100%",
                                  height: "2px",
                                  color: "white",
                                }}
                              />
                            </>
                          )}
                          {cookingMedium && (
                            <>
                              <div className="m-meal-item__title mt-2">
                                Fats
                              </div>
                              <ul className="list-group ">
                                {cookingMedium.length > 0 &&
                                  cookingMedium.slice(1).map((c, i) => (
                                    <>
                                      <ColumnItemList c={c} />
                                      {cookingMedium.length - 2 !== i && (
                                        <li className="list-group-item meal-item__list text-center bold">
                                          OR
                                        </li>
                                      )}
                                    </>
                                  ))}
                              </ul>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                {/* 3rd column */}
                <Col
                  span={24}
                  sm={24}
                  md={5}
                  lg={5}
                  className="o-meal-table__items"
                >
                  <div className="a-meal-food-small">{columns[2]}</div>
                  <div className="o-meal-table__col">
                    <div className="m-meal-item">
                      <div className="m-meal-item__header">
                        <div className="m-meal-item__content">
                          {carbsData && (
                            <>
                              <div className="m-meal-item__title">Carbs</div>
                              <ul className="list-group ">
                                {carbsData.length > 0 && (
                                  <Row justify="space-between">
                                    <Col>
                                      <li className="list-group-item meal-item__list">
                                        {carbsData[carbsData.length - 1]?.item}{" "}
                                        :{" "}
                                      </li>
                                    </Col>
                                    <Col>
                                      <li className="list-group-item meal-item__list">
                                        {carbsData[carbsData.length - 1]?.value}{" "}
                                        :
                                      </li>
                                    </Col>
                                  </Row>
                                )}
                                {/* {carbsData.length > 0 &&
                                  carbsData
                                    .slice(0, carbsData.length - 1)
                                    .map((c, i) => (
                                      <>
                                        <Row
                                          key={c.item}
                                          justify="space-between"
                                        >
                                          <Col>
                                            <li className="list-group-item meal-item__list">
                                              {c.item} :
                                            </li>
                                          </Col>
                                          <Col>
                                            <li className="list-group-item meal-item__list">
                                              {c.value}
                                            </li>
                                          </Col>
                                        </Row>
                                        {i < carbsData.length - 2 && (
                                          <li className="list-group-item meal-item__list text-center bold">
                                            OR
                                          </li>
                                        )}
                                      </>
                                    ))} */}
                              </ul>
                              <hr
                                style={{
                                  width: "100%",
                                  height: "2px",
                                  color: "white",
                                }}
                              />
                            </>
                          )}
                          {protiensData && (
                            <>
                              <div className="m-meal-item__title mt-2">
                                Protiens
                              </div>
                              <ul className="list-group ">
                                {protiensData.length > 0 && (
                                  <Row justify="space-between">
                                    <Col>
                                      <li className="list-group-item meal-item__list">
                                        {
                                          protiensData[protiensData.length - 1]
                                            ?.item
                                        }{" "}
                                        :
                                      </li>
                                    </Col>
                                    <Col>
                                      <li className="list-group-item meal-item__list">
                                        {
                                          protiensData[protiensData.length - 1]
                                            ?.value
                                        }
                                      </li>
                                    </Col>
                                  </Row>
                                )}

                                {/* {protiensData.length > 0 &&
                                  protiensData
                                    .slice(0, protiensData.length - 1)
                                    .map((c, i) => (
                                      <>
                                        <Row
                                          key={c.item}
                                          justify="space-between"
                                        >
                                          <Col>
                                            <li className="list-group-item meal-item__list">
                                              {c.item} :
                                            </li>
                                          </Col>
                                          <Col>
                                            <li className="list-group-item meal-item__list">
                                              {c.value}
                                            </li>
                                          </Col>
                                        </Row>
                                        {i < protiensData.length - 2 && (
                                          <li className="list-group-item meal-item__list text-center bold">
                                            OR
                                          </li>
                                        )}
                                      </>
                                    ))} */}
                              </ul>
                              <hr
                                style={{
                                  width: "100%",
                                  height: "2px",
                                  color: "white",
                                }}
                              />
                            </>
                          )}
                          {fatsData && (
                            <>
                              <div className="m-meal-item__title mt-2">
                                Fats
                              </div>
                              <ul className="list-group ">
                                {fatwithoutOil.length > 0 && (
                                  <Row justify="space-between">
                                    <Col>
                                      <li className="list-group-item meal-item__list">
                                        {
                                          fatwithoutOil[
                                            fatwithoutOil.length - 1
                                          ]?.item
                                        }{" "}
                                        :
                                      </li>
                                    </Col>
                                    <Col>
                                      <li className="list-group-item meal-item__list">
                                        {
                                          fatwithoutOil[
                                            fatwithoutOil.length - 1
                                          ]?.value
                                        }
                                      </li>
                                    </Col>
                                  </Row>
                                )}

                                {/* {fatsData.length > 0 &&
                                  fatsData
                                    .slice(0, fatsData.length - 1)
                                    .map((c, i) => (
                                      <>
                                        <Row
                                          key={c.item}
                                          justify="space-between"
                                        >
                                          <Col>
                                            <li className="list-group-item meal-item__list">
                                              {c.item} :
                                            </li>
                                          </Col>
                                          <Col>
                                            <li className="list-group-item meal-item__list">
                                              {c.value}
                                            </li>
                                          </Col>
                                        </Row>
                                        {i < fatsData.length - 2 && (
                                          <li className="list-group-item meal-item__list text-center bold">
                                            OR
                                          </li>
                                        )}
                                      </>
                                    ))} */}
                              </ul>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                {/* 4th column */}
                <Col
                  span={24}
                  sm={24}
                  md={5}
                  lg={5}
                  className="o-meal-table__items"
                >
                  <div className="a-meal-food-small">{columns[3]}</div>
                  <div className="o-meal-table__col">
                    <div className="m-meal-item">
                      <div className="m-meal-item__header">
                        <div className="m-meal-item__content">
                          {carbsData && (
                            <>
                              <div className="m-meal-item__title">Carbs</div>
                              <ul className="list-group ">
                                {carbsWithoutFruits &&
                                carbsWithoutFruits.length > 0 ? (
                                  <WithoutFruitsCarbsData
                                    carbsData={carbsWithoutFruits.slice(
                                      0,
                                      carbsWithoutFruits.length === 1
                                        ? carbsWithoutFruits.length
                                        : carbsWithoutFruits.length - 1
                                    )}
                                    column="4"
                                  />
                                ) : (
                                  <AllCarbsData
                                    carbsData={carbsData}
                                    orLength={1}
                                  />
                                )}
                              </ul>
                              <hr
                                style={{
                                  width: "100%",
                                  height: "2px",
                                  color: "white",
                                }}
                              />
                            </>
                          )}
                          {protiensData && (
                            <>
                              <div className="m-meal-item__title mt-2">
                                Protiens
                              </div>
                              <ul className="list-group ">
                                {protiensData
                                  .slice(
                                    0,
                                    protiensData.length === 1
                                      ? protiensData.length
                                      : protiensData.length - 1
                                  )
                                  .map((c, i) => (
                                    <>
                                      <ColumnItemList c={c} />
                                      {i < protiensData.length - 2 && (
                                        <li className="list-group-item meal-item__list text-center bold">
                                          OR
                                        </li>
                                      )}
                                    </>
                                  ))}
                              </ul>
                              <hr
                                style={{
                                  width: "100%",
                                  height: "2px",
                                  color: "white",
                                }}
                              />
                            </>
                          )}
                          {cookingMedium && (
                            <>
                              <div className="m-meal-item__title mt-2">
                                Fats
                              </div>
                              <ul className="list-group ">
                                {cookingMedium
                                  .slice(0, fatsData.length - 1)
                                  .map((c, i) => (
                                    <>
                                      <ColumnItemList c={c} />
                                      {cookingMedium.length - 1 !== i && (
                                        <li className="list-group-item meal-item__list text-center bold">
                                          OR
                                        </li>
                                      )}
                                    </>
                                  ))}

                                {/* {cookingMedium.length > 0 && (
                                  <Row justify="space-between">
                                    <Col>
                                      <li className="list-group-item meal-item__list">
                                        {
                                          cookingMedium[
                                            cookingMedium.length - 1
                                          ]?.item
                                        }{" "}
                                        :
                                      </li>
                                    </Col>
                                    <Col>
                                      <li className="list-group-item meal-item__list">
                                        {
                                          cookingMedium[
                                            cookingMedium.length - 1
                                          ]?.value
                                        }
                                      </li>
                                    </Col>
                                  </Row>
                                )} */}
                              </ul>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <div className="o-meal-table__overlay"></div>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DietTable;
