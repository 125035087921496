import React from "react";
import {  Switch, Route, } from "react-router-dom";
import SelectGender from "./pages/selectGender/index.jsx";
import SelectPhysicalActivity from "./pages/selectPhysicalActivity/index.jsx";
import SelectGridNonVeg from "./pages/selectGridNonVeg/index.jsx";
import SelectedGridVeg from "./pages/selectGridVeg/index.jsx";
import SelectedFruits from "./pages/selectFruits/index.jsx";
import SelectedDiary from "./pages/selectDairy/index.jsx";
import SelectTypicalDay from "./pages/selectTypicalDay/index.jsx";
import SelectBadHabits from "./pages/selectBadHabbit/index.jsx"
import ImportantDetails from "./pages/ImportantDetails/index.jsx";
import CookingMedium from "./pages/selectCookingMedium/index.jsx"
import SelectFat from "./pages/selectFat";
import Paynow from "./pages/DashBoard/index"; 
import Policies from "./pages/Policies";
import AboutUs from "./pages/aboutUS";
import Test from './pages/Test'
const RouterComponent = (props) => {
  return (
    <Switch>
      <Route path="/" exact component={SelectGender} />
      <Route path="/SPA" exact component={SelectPhysicalActivity} />
      <Route path="/SGNV" exact component={SelectGridNonVeg} />
      <Route path="/SGV" exact component={SelectedGridVeg} />
      <Route path="/SGF" exact component={SelectedFruits} />
      <Route path="/SGD" exact component={SelectedDiary} />
      <Route path="/SBH" exact component={SelectBadHabits} />
      <Route path="/SFS" exact component={SelectFat} />
      <Route path="/STD" exact component={SelectTypicalDay} />
      <Route path="/SCM" exact component={CookingMedium} />

      <Route path="/ID" exact component={ImportantDetails} />
      <Route path="/DB/:id" exact component={Paynow} />y
      <Route path="/aboutus" exact component={AboutUs}/>
      <Route path="/Policy/:type" exact component={Policies} />


    </Switch>
  );
};

export default RouterComponent;