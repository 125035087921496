import React, { useState } from "react";
import { Row, Col, message } from "antd";

import ProgressBar from "../../components/ProgressBar";
import Wrapper from "../../components/Wrapper.jsx";
import { useRecoilState } from "recoil";
import { userAtom } from "../../Recoil/Atoms/user";
// import Images from "../../assets/images.js";

import { ReactComponent as Oranges } from "../../assets/orange-slice.svg";
import { ReactComponent as Apple } from "../../assets/apple.svg";
import { ReactComponent as Banana } from "../../assets/banana.svg";
import { ReactComponent as Pineapple } from "../../assets/pineapple.svg";
import { ReactComponent as Mausambi } from "../../assets/mausambi.svg";
import { ReactComponent as Watermelon } from "../../assets/watermelon.svg";
import { ReactComponent as Avacado } from "../../assets/ava.svg";
import { ReactComponent as OrangeSelected } from "../../assets/orange-sliceSelected.svg";
import { ReactComponent as AppleSelected } from "../../assets/appleSelected.svg";
import { ReactComponent as BananaSelected } from "../../assets/bananaSelected.svg";
import { ReactComponent as PineappleSelected } from "../../assets/pineappleselected.svg";
import { ReactComponent as MausambiSelected } from "../../assets/mausambiSelected.svg";
import { ReactComponent as WatermelonSelected } from "../../assets/watermelonSeelcted.svg";
import { ReactComponent as AvacadoSelected } from "../../assets/avaSelected.svg";

import { ReactComponent as Right } from "../../assets/Icon awesome-check.svg";
import { ReactComponent as Wrong } from "../../assets/Icon metro-cross.svg";

let LIST_VEG = [
  {
    id: 1,
    Title: "Orange",
    icon: Oranges,
    selectedIcon: OrangeSelected,
  },
  {
    id: 2,
    Title: "Apple",
    icon: Apple,
    selectedIcon: AppleSelected,
  },
  {
    id: 3,
    Title: "Banana",
    icon: Banana,
    selectedIcon: BananaSelected,
  },
  {
    id: 4,
    Title: "Pineapple",
    icon: Pineapple,
    selectedIcon: PineappleSelected,
  },
  {
    id: 5,
    Title: "Grapes",
    icon: Oranges,
    selectedIcon: OrangeSelected,
  },
  {
    id: 6,
    Title: "Mosambi",
    icon: Mausambi,
    selectedIcon: MausambiSelected,
  },
  // {
  //   id: 7,
  //   Title: "Watermelon",
  //   icon: Watermelon,
  //   selectedIcon: WatermelonSelected,
  // },
  // {
  //   id: 8,
  //   Title: "Avocado",
  //   icon: Avacado,
  //   selectedIcon: AvacadoSelected,
  // },
];

const SelectGender = (props) => {
  const [selected, setSelected] = useState([]);
  const [userData, setUserData] = useRecoilState(userAtom);
  const [update, setupdater] = useState(false);

  const selectedMethod = (item) => {
    if (selected.indexOf(item.Title) !== -1) {
      let tempSelected = selected;
      tempSelected.splice(tempSelected.indexOf(item.Title), 1);
      console.log("TempSelected", tempSelected);
      setSelected(tempSelected);
    } else {
      setSelected([...selected, item.Title]);
    }
    setupdater(!update);
  };
  const selectNonVeg = async () => {
    if (selected.length > 2) {
      await setUserData({ ...userData, fruits: selected });
      console.log(userData);
      props.history.push("/SGD");
    } else {
      message.error("Please select at least three options");
    }
  };
  return (
    <Wrapper {...props}>
      <Row className={"select_row"} align={"top"} justify="start">
        <Col align="left" span={8} xs={24} md={8}>
          <div className="blockname">
            <div style={{ marginLeft: "10rem" }} className="blocknametext">
              Select
            </div>
            <div style={{ marginLeft: "8rem" }} className="blocknametext">
              Fruits
            </div>
          </div>
        </Col>
        <Col align="center" span={8} xs={24} md={16}>
          <Row
            style={{ marginTop: "3rem" }}
            gutter={10}
            align="middle"
            justify="center"
          >
            <Col>
              <Right className="icon-change" />
            </Col>
            <Col className="background--grey">
              <h4 className="heading">Include / Exclude</h4>
            </Col>
            <Col>
              <Wrong className="icon-change" />
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <h5 className="sub_heading background--grey">
              PLEASE CLICK TO INCLUDE PRODUCTS YOU LIKE:
            </h5>
          </Row>

          <Row gutter={24} align="middle" justify="center">
            {LIST_VEG.map((item, key) => {
              return (
                <Col
                  onClick={() => selectedMethod(item)}
                  key={key}
                  align="center"
                  className={
                    selected.includes(item.Title) ? "block__selected" : "block"
                  }
                  span={6}
                >
                  <Row
                    flex={1}
                    style={{ width: "100%" }}
                    align="middle"
                    justify="end"
                  >
                    {selected.includes(item.Title) ? (
                      <Right height="1rem" />
                    ) : (
                      <Wrong height="1rem" />
                    )}
                  </Row>
                  {selected.includes(item.Title) ? (
                    <item.selectedIcon height="5rem" />
                  ) : (
                    <item.icon height="5rem" />
                  )}
                  <h4 className="block_header">{item.Title}</h4>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <div onClick={() => selectNonVeg()} className="rowlabel">
          Continue
        </div>
      </Row>
      <ProgressBar value={73} />
    </Wrapper>
  );
};

export default SelectGender;
