import React from "react";
import{Row} from "antd";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const ProgressBarComponent = ({value})=>{
    return(
        <Row>
        <div className="rowFixed">
        <h2 className="percentage__show">{`${value}%`}</h2>
          <ProgressBar
            percent={value}
            filledBackground="linear-gradient(to right, #32f0e6, #14a098)"
          
            
          >
            
          </ProgressBar>
        </div>
      </Row>
    )
}

ProgressBarComponent.defaultProps={
value:30
}
export default ProgressBarComponent;