import React, { useState } from "react";
import { Row, Col, message } from "antd";
import ProgressBar from "../../components/ProgressBar";
import Wrapper from "../../components/Wrapper";
import { useRecoilState } from "recoil";
import { userAtom } from "../../Recoil/Atoms/user";
// import Images from "../../assets/images.js";

import { ReactComponent as Cashew } from "../../assets/cashew.svg";
import { ReactComponent as CashewSelected } from "../../assets/cashew-1.svg";
import { ReactComponent as Nuts } from "../../assets/nut.svg";
import { ReactComponent as Almonds } from "../../assets/almond.svg";
import { ReactComponent as AlmondSelected } from "../../assets/almond-1.svg";
import { ReactComponent as Nutselected } from "../../assets/nutSelected.svg";
import { ReactComponent as Peanut } from "../../assets/peanut.svg";
import { ReactComponent as PeanutSelected } from "../../assets/peanut-1.svg";
import { ReactComponent as Pista } from "../../assets/pistachio.svg";
import { ReactComponent as PistaSelected } from "../../assets/pistachio-1.svg";

import { ReactComponent as Right } from "../../assets/Icon awesome-check.svg";
import { ReactComponent as Wrong } from "../../assets/Icon metro-cross.svg";

let LIST_VEG = [
  {
    id: 1,
    Title: "ALmonds",
    icon: Almonds,
    selectedIcon: AlmondSelected,
  },
  {
    id: 2,
    Title: "Peanut",
    icon: Peanut,
    selectedIcon: PeanutSelected,
  },
  {
    id: 3,
    Title: "Cashew",
    icon: Cashew,
    selectedIcon: CashewSelected,
  },
  {
    id: 4,
    Title: "Pista",
    icon: Pista,
    selectedIcon: PistaSelected,
  },
  // {
  //   id: 5,
  //   Title: "FreshCream",
  //   icon: Milk,
  //   selectedIcon: MilkSelected,
  // },
  // {
  //   id: 6,
  //   Title: "Ghee",
  //   icon: Milk,
  //   selectedIcon: MilkSelected,
  // },
  {
    id: 7,
    Title: "Walnuts",
    icon: Nuts,
    selectedIcon: Nutselected,
  },
];

const SelectGender = (props) => {
  const [selected, setSelected] = useState([]);
  const [userData, setUserData] = useRecoilState(userAtom);
  const [update, setupdater] = useState(false);

  const selectedMethod = (item) => {
    if (selected.indexOf(item.Title) !== -1) {
      let tempSelected = selected;
      tempSelected.splice(tempSelected.indexOf(item.Title), 1);
      console.log("TempSelected", tempSelected);
      setSelected(tempSelected);
    } else {
      setSelected([...selected, item.Title]);
    }
    setupdater(!update);
  };
  const selectNonVeg = async () => {
    if (selected.length > 2) {
      await setUserData({ ...userData, Fat: selected });
      props.history.push("/SCM");
      console.log(userData);
    } else {
      message.error("Please select atleast 3 options");
    }
  };
  return (
    <Wrapper {...props}>
      <Row className={"select_row"} align={"top"} justify="start">
        <Col align="left" span={8} xs={24} md={8}>
          <div className="blockname">
            <div style={{ marginLeft: "10rem" }} className="blocknametext">
              Select
            </div>
            <div style={{ marginLeft: "8rem" }} className="blocknametext">
              Nuts
            </div>
          </div>
        </Col>
        <Col align="center" span={8} xs={24} md={16}>
          <Row
            style={{ marginTop: "3rem" }}
            gutter={10}
            align="middle"
            justify="center"
          >
            <Col>
              <Right className="icon-change" />
            </Col>
            <Col className="background--grey">
              <h4 className="heading">Include / Exclude</h4>
            </Col>
            <Col>
              <Wrong className="icon-change" />
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <h5 className="sub_heading background--grey">
              PLEASE CLICK TO INCLUDE PRODUCTS YOU LIKE:
            </h5>
          </Row>

          <Row gutter={[16, 24]} align="middle" justify="center">
            {LIST_VEG.map((item, key) => {
              return (
                <Col
                  onClick={() => selectedMethod(item)}
                  key={key}
                  align="center"
                  className={
                    selected.includes(item.Title) ? "block__selected" : "block"
                  }
                  span={6}
                >
                  <Row
                    flex={1}
                    style={{ width: "100%" }}
                    align="middle"
                    justify="end"
                  >
                    {selected.includes(item.Title) ? (
                      <Right height="1rem" />
                    ) : (
                      <Wrong height="1rem" />
                    )}
                  </Row>
                  {selected.includes(item.Title) ? (
                    <item.selectedIcon height="5rem" />
                  ) : (
                    <item.icon height="5rem" />
                  )}
                  <h4 className="block_header">{item.Title}</h4>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <div onClick={() => selectNonVeg()} className="rowlabel">
          Continue
        </div>
      </Row>
      <ProgressBar value={90} />
    </Wrapper>
  );
};

export default SelectGender;
