import React from "react";
import { Row, Col } from "antd";
import ProgressBar from "../../components/ProgressBar";
import Wrapper from "../../components/Wrapper";

let LIST = [
  {
    id: 1,
    Title: "At The Office",
  },
  {
    id: 2,
    Title: "At Office, But I Go Out On a Regular Basis",
  },
  {
    id: 3,
    Title: "I Spend The Better Part Of The Day On Foot",
  },
  {
    id: 4,
    Title: "Manual Labour",
  },
  {
    id: 5,
    Title: "I Mostly Stay At Home",
  },
];
const SelectGender = (props) => {

  const selectActivity = async (value) => {

    // props.history.push("/SFS");
    props.history.push("/SBH")
  };
  return (
    <Wrapper {...props}>
      <Row className={"select_row"} align={"top"} justify="start">
        <Col align="left" span={8} xs={24} md={8}>
          <div className="blockname">
            <div style={{ marginLeft: "10rem" }} className="blocknametext">
              Select
            </div>
            <div style={{ marginLeft: "8rem" }} className="blocknametext">
            Lifestyle
            </div>
          </div>
        </Col>
        <Col align="center" span={8} xs={24} md={8}>
          {LIST.map((item, key) => {
            return (
              <div
                onClick={() => selectActivity(item.Type)}
                key={key}
                className="rowlabel"
              >
                {item.Title}
              </div>
            );
          })}
        </Col>
      </Row>

      <ProgressBar value={34} />
    </Wrapper>
  );
};

export default SelectGender;
