// import RazorPay from "razorpay";
import { RazorpayIDLive, RazorpayIDTest } from "../config";
import IMages from "../assets/images";
import {
  UpdatePaymentForUser,
  firebaseBaseFunctions,
  sendEmail,
} from "./FirebaseAction";
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
export const PaymentMethod = async (uid, email, phoneNumber, cb) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  // creating a new order
  // const result = await axios.post("http://localhost:5000/payment/orders");
  const result = await firebaseBaseFunctions();
  if (!result) {
    alert("Server error. Are you online?");
    return;
  }

  // Getting the order details back
  const { amount, id: order_id, currency } = result;

  const options = {
    key: RazorpayIDLive, // Enter the Key ID generated from the Dashboard
    amount: amount.toString(),
    currency: currency,
    name: "My Diet Cart",
    description: "Test Transaction",
    image: IMages.Logo,
    order_id: order_id,
    handler: async function (response) {
      const data = {
        orderCreationId: order_id,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
        email: email,
        phone: phoneNumber,
        price: amount.toString(),
        date: new Date(),
      };
      let isPaymentSuccess = await UpdatePaymentForUser(uid, data);
      cb(isPaymentSuccess);
      // const result = await axios.post("http://localhost:5000/payment/success", data);
      // const result = await axios.post("http://localhost:5000/payment/welcomeMail",{email:"visheshtungare.cs@gmail.com",uid:uid})
      sendEmail(uid, email);

      // alert(result.data.msg);
    },
    prefill: {
      name: email,
      email: email,
      contact: phoneNumber,
    },
    notes: {
      address: "Purchase made using the MDC",
    },
    theme: {
      color: "#16ffbd",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};
