import React from 'react'
import { Row, Col } from "antd";

const DietToolbar = ({week, setWeek, prevBtn, nextBtn}) => {

    const scale = [
        {id:1, value:'50', unit:'gm', equal:'Bowl', img:''},
        {id:2, value:'250', unit:'ml', equal:'Glass', img:''},
        {id:3, value:'1', unit:'unit', equal:'Unit', img:''}
    ]
    return (
        <>
        <Row>
            <Col span={24} lg={12} md={12} sm={24}>
            <div className="o-meal__meal-table-head o-meal-head">
                <div className="o-meal-head__meal-weeks m-meal-weeks">
                    <div className="m-meal-weeks__bbutton m-meal-weeks__bbutton--left a-bbutton--nav a-bbutton button-weeks-left" onClick={prevBtn}>
                        <span className="a-bbutton__icon a-icon" >
                            <i className="fa fa-chevron-left" ></i>
                        </span>
                    </div>
                    <div className="m-meal-weeks__select">
                        <span className="m-meal-weeks__icon a-icon">
                            <i className="fa fa-calendar" ></i>
                        </span>
                        <div className="m-meal-weeks__input a-input">
                            <div className="a-input__select">
                                <div className="a-input__triangle"></div>
                                <select name="week" autoComplete="off" className="a-input__input--big a-input__input a-input__input--select" value={week} onChange={(e)=>setWeek(e.target.value)}>
                                    <option value="1">Week 1</option>
                                    <option value="2">Week 2</option>
                                    <option value="3">Week 3</option>
                                    <option value="4">Week 4</option>
                                    <option value="5">Week 5</option>
                                    <option value="6">Week 6</option>
                                    <option value="7">Week 7</option>
                                    <option value="8">Week 8</option>
                                    <option value="9">Week 9</option>
                                    <option value="10">Week 10</option>
                                    <option value="11">Week 11</option>
                                    <option value="12">Week 12</option>
                                    <option value="13">Week 13</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="m-meal-weeks__bbutton m-meal-weeks__bbutton--right a-bbutton--nav a-bbutton button-weeks-right" onClick={nextBtn}>
                        <span className="a-bbutton__icon a-icon" >
                            <i className="fa fa-chevron-right" ></i>
                        </span>
                    </div>
                </div>
            </div>
            </Col>
                <Col span={24} lg={12} md={12} sm={24}>
                    <div className="summary-info justify-content-lg-end justify-content-sm-start ">
                        {scale.map(x =>(
                        <div className="summary-info-item" key={x.id} >
                            <span className="summary-info-icon">
                                {/* <i className={x.icon} /> */}
                            </span>

                            <div className="summary-info-content ">
                                1 {x.equal}
                                <div className="summary-info-subtitle"> = {x.value} {x.unit}</div>
                            </div>
                        </div>))}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default DietToolbar