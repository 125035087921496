import React from 'react'
import { Row, Col } from 'antd'

const ColumnItemList = ({ c }) => {
    return (
        <>
            <Row key={c.item} justify="space-between">
                <Col>
                    <li className="list-group-item meal-item__list">
                        {c.item} :
                                </li>
                </Col>
                <Col>
                    <li className="list-group-item meal-item__list">
                        {c.value}
                    </li>
                </Col>
            </Row>
        </>
    )
}

export default ColumnItemList
