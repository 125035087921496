import React, { useState } from "react";
import { Row, Col, message, Popover } from "antd";

//components
import ProgressBar from "../../components/ProgressBar";
import Wrapper from "../../components/Wrapper";
import { useRecoilState } from "recoil";
import { userAtom } from "../../Recoil/Atoms/user";

import { ReactComponent as Butter } from "../../assets/butter.svg";
import { ReactComponent as ButterSelected } from "../../assets/butter-1.svg";
import { ReactComponent as OliveOil } from "../../assets/olive-oil.svg";
import { ReactComponent as OliveOilSelected } from "../../assets/olive-oil-1.svg";
import { ReactComponent as CoconutOil } from "../../assets/coconut-oil.svg";
import { ReactComponent as CoconutOilSelected } from "../../assets/coconut-oil-1.svg";
import { ReactComponent as WheatSelected } from "../../assets/wheatselected.svg";
import { ReactComponent as RiceSelected } from "../../assets/riceselected.svg";
import { ReactComponent as PotatoSelected } from "../../assets/potatoselected.svg";
import { ReactComponent as OatsSelected } from "../../assets/wheatselected.svg";
import { ReactComponent as Right } from "../../assets/Icon awesome-check.svg";
import { ReactComponent as Wrong } from "../../assets/Icon metro-cross.svg";
import { ReactComponent as CheeseSelected } from "../../assets/cheeseSelected.svg";
import { ReactComponent as Cheese } from "../../assets/cheese.svg";
import { ReactComponent as Milk } from "../../assets/milk.svg";
import { ReactComponent as MilkSelected } from "../../assets/milkSelected.svg";

let LIST_VEG = [
  {
    id: 1,
    Title: "Ghee",
    icon: Butter,
    selectedIcon: ButterSelected,
    content: "FOOD TO AVOID WITH CASEIN ALLERGY",
  },
  {
    id: 2,
    Title: "Butter",
    icon: Butter,
    selectedIcon: ButterSelected,
    content: "FOOD TO AVOID WITH CASEIN ALLERGY",
  },
  {
    id: 3,
    Title: "Olive oil",
    icon: OliveOil,
    selectedIcon: OliveOilSelected,
    content: "OLIVE POLLEN ALLERGY SHOULD AVOID",
  },
  {
    id: 4,
    Title: "Groundnut oil",
    icon: OliveOil,
    selectedIcon: OliveOilSelected,
    content: "AVOID WITH PEANUT ALLERGY",
  },
  {
    id: 5,
    Title: "Coconut oil",
    icon: CoconutOil,
    selectedIcon: CoconutOilSelected,
  },
];

const SelectGender = (props) => {
  const [selected, setSelected] = useState([]);
  const [userData, setUserData] = useRecoilState(userAtom);
  const [update, setupdater] = useState(false);

  const selectedMethod = (item) => {
    if (selected.indexOf(item.Title) !== -1) {
      let tempSelected = selected;
      tempSelected.splice(tempSelected.indexOf(item.Title), 1);
      console.log("TempSelected", tempSelected);
      setSelected(tempSelected);
    } else {
      setSelected([...selected, item.Title]);
    }
    setupdater(!update);
  };
  const selectNonVeg = async () => {
    if (selected.length > 2) {
      await setUserData({ ...userData, Fat: [...userData.Fat, ...selected] });

      props.history.push("/ID");
    } else {
      message.error("Please select at least 3 option ");
    }
  };
  return (
    <Wrapper {...props}>
      <Row className={"select_row"} align={"top"} justify="start">
        <Col align="left" span={8} xs={24} md={8}>
          <div className="blockname">
            <div style={{ marginLeft: "10rem" }} className="blocknametext">
              SELECT
            </div>
            <div style={{ marginLeft: "8rem" }} className="blocknametext">
              Cooking
            </div>
            <div style={{ marginLeft: "5.8rem" }} className="blocknametext">
              Medium
            </div>
          </div>
        </Col>
        <Col align="center" span={8} xs={24} md={8}>
          <Row
            style={{ marginTop: "3rem" }}
            gutter={10}
            align="middle"
            justify="center"
          >
            <Col>
              <Right className="icon-change" />
            </Col>
            <Col className="background--grey">
              <h4 className="heading">Include / Exclude</h4>
            </Col>
            <Col>
              <Wrong className="icon-change" />
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <h5 className="sub_heading background--grey">
              PLEASE CLICK TO INCLUDE PRODUCTS YOU LIKE:
            </h5>
          </Row>

          <Row gutter={20} align="middle" justify="center">
            {LIST_VEG.map((item, key) => {
              return (
                <Popover
                  content={item.content}
                  title={item.Title}
                  trigger="hover"
                >
                  <Col
                    onClick={() => selectedMethod(item)}
                    key={key}
                    align="center"
                    className={
                      selected.includes(item.Title)
                        ? "block__selected"
                        : "block"
                    }
                    span={8}
                  >
                    <Row
                      flex={1}
                      style={{ width: "100%" }}
                      align="middle"
                      justify="end"
                    >
                      {selected.includes(item.Title) ? (
                        <Right height="1rem" />
                      ) : (
                        <Wrong height="1rem" />
                      )}
                    </Row>
                    {selected.includes(item.Title) ? (
                      <item.selectedIcon height="5rem" />
                    ) : (
                      <item.icon height="5rem" />
                    )}
                    <h4 className="block_header">{item.Title}</h4>
                  </Col>
                </Popover>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <div onClick={() => selectNonVeg()} className="rowlabel">
          Continue
        </div>
      </Row>

      <ProgressBar value={95} />
    </Wrapper>
  );
};

export default SelectGender;
