import React from "react";
//recoil
// import { useRecoilState } from "recoil";
// import { userAtom } from "../../Recoil/Atoms/user";
// page
// import PayNow from "./PayNow";
import MyDietPlan from "./MyDietPlan";
// components
import Wrapper from '../../components/Wrapper'

const Dashboard = (props) => {
  // const [userData, setUserData] = useRecoilState(userAtom);

  // console.log('userData', userData)

  return (
    <>
      <Wrapper {...props}>
        {/* <PayNow/> */}
        <MyDietPlan {...props} />
      </Wrapper>
    </>
  );
};

export default Dashboard;