import React, { useState, useEffect } from "react";
// components
import Cards from "../../components/SummaryCards";
// assets
import { ReactComponent as Man } from "../../assets/man.svg";
import { ReactComponent as Woman } from "../../assets/woman.svg";

import bmi from "../../assets/summary/bmi.png";
import mage from "../../assets/summary/mage.png";
import waterdrop from "../../assets/summary/water-drop.png";
import weightimg from "../../assets/summary/weight.png";
//actions
import { PaymentMethod } from "../../Actions/PaymentActions";

const Summary = (props) => {
  // console.log("props",props)
  const {
    uid,
    userInfo,
    userInfo: { age, height, currentWeight, gender,isPaid,email,phone },
    summary,
    summary: { bmiInfo, water, targetWeight },
  } = props;
  const[updateView,setUpdateView]=useState(false);
  const [info, setInfo] = useState([
    {
      id: 1,
      icon: "fa fa-venus-mars fa-3x",
      title: `${gender === "male" ? "Man" : "Women"}`,
      content: "image",
    },
    {
      id: 2,
      icon: "fa fa-calendar-plus-o fa-3x",
      title: "Years",
      content: age,
    },
    {
      id: 3,
      icon: "fa fa-text-height fa-3x",
      title: "ft",
      content: `${height ? height?.ft : 0}.${height ? height?.in : 0}`,
    },
    {
      id: 4,
      icon: "fa fa-balance-scale fa-3x",
      title: "Kg",
      content: `${currentWeight}`,
    },
  ]);

  const [summaryList, setSummaryList] = useState([
    {
      id: 1,
      image: bmi,
      title: "BMI",
      subtitle: `Your BMI is :${bmiInfo.BMI}`,
      content: bmiInfo.status,
      color: "rgb(243, 173, 4)",
    },
    {
      id: 2,
      image: mage,
      title: "METABOLIC AGE",
      subtitle: "Your Metabolic Age :",
      content: `${age} years`,
      color: "rgb(117, 205, 51)",
    },
    // {id:3, image:'', title:'CALORIES', subtitle:'Recommended Calories :', content:'1937 Cal', color:'rgb(243, 173, 4)'},
    {
      id: 4,
      image: waterdrop,
      title: "WATER",
      subtitle: "Recommended Water :",
      content: water,
      color: "#fff",
    },
    {
      id: 5,
      image: weightimg,
      title: "TARGET WEIGHT",
      subtitle:
        "Target Weight:",
      content: `${targetWeight} Kilograms`,
      color: "rgb(117, 205, 51)",
    },
  ]);
  useEffect(() => {
    console.log("user>>",userInfo)
    setInfo([
      {
        id: 1,
        icon: "fa fa-venus-mars fa-3x",
        title: `${gender === "male" ? "Man" : "Women"}`,
        content: "image",
      },
      {
        id: 2,
        icon: "fa fa-calendar-plus-o fa-3x",
        title: "Years",
        content: age,
      },
      {
        id: 3,
        icon: "fa fa-text-height fa-3x",
        title: "ft",
        content: `${height?.ft}.${height?.in}`,
      },
      {
        id: 4,
        icon: "fa fa-balance-scale fa-3x",
        title: "Kg",
        content: `${currentWeight}`,
      },
    ]);
    setSummaryList([
      {
        id: 1,
        image: bmi,
        title: "BMI",
        subtitle: `Your BMI is :${bmiInfo.BMI}`,
        content: bmiInfo.status,
        color: "rgb(243, 173, 4)",
      },
      {
        id: 2,
        image: mage,
        title: "METABOLIC AGE",
        subtitle: "Your Metabolic Age :",
        content: `${age} years`,
        color: "rgb(117, 205, 51)",
      },
      // {id:3, image:'', title:'CALORIES', subtitle:'Recommended Calories :', content:'1937 Cal', color:'rgb(243, 173, 4)'},
      {
        id: 4,
        image: waterdrop,
        title: "WATER",
        subtitle: "Recommended Water :",
        content: water,
        color: "#fff",
      },
      {
        id: 5,
        image: weightimg,
        title: "TARGETED WEIGHT",
        subtitle:
          "Target weight",
        content: `${targetWeight} Kilograms`,
        color: "rgb(117, 205, 51)",
      },
    ]);
  }, [userInfo, summary]);

  return (
    <>
      {/* <div className="o-profile-summary"> */}
    
      <div className="container-fluid summary-content">
      {!isPaid && (
        <div>
          <h2 className="percentage__show">Just One Step Away</h2>
          <div
            className="rowlabel"
            onClick={() =>
              PaymentMethod(uid,email,phone, (value) => {
                setUpdateView(value);
                window.location.reload();
              })
            }
          >
            PayNow INR.
            <span style={{ color: "red" }}>
              {" "}
              <strike> 1499-</strike>
              <span style={{ color: "#fff" }}> 999/- </span>
            </span>
            only
          </div>
        </div>
      )}
        <div className="summary_head">
          <div className="row w-100">
            <div className="col-md-4 col-lg-5">
              <div className="summary-title">Your Profile Summary</div>
            </div>
            <div className="col-md-8 col-lg-7 px-0">
              <div className="summary-info justify-content-lg-end justify-content-sm-start ">
                {info.map((x, i) => {
                  let content =
                    x.content === "image" ? (
                      gender === "male" ? (
                        <Man />
                      ) : (
                        <Woman />
                      )
                    ) : (
                      x.content
                    );
                  return (
                    <div className="summary-info-item" key={i}>
                      <span className="summary-info-icon">
                        <i className={x.icon} />
                      </span>

                      <div className="summary-info-content ">
                        {content}
                        <div className="summary-info-subtitle">{x.title}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="row summary-items">
          {summaryList.map((s, i) => {
            return <Cards data={s} key={i} />;
          })}
        </div>
        {!isPaid && (
          <div>
            <h2 className="percentage__show">Just One Step Away</h2>
            <div
              className="rowlabel"
              onClick={() =>
                PaymentMethod(uid,email,phone,(value) => {
                  setUpdateView(value);
                  window.location.reload();
                })
              }
            >
              PayNow INR.
              <span style={{ color: "red" }}>
                {" "}
                <strike> 1499-</strike>
                <span style={{ color: "#fff" }}> 999/- </span>
              </span>
              only
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </>
  );
};

export default Summary;
