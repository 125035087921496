import { atom } from "recoil";

export const userAtom = atom({
    key: 'userDetails',//in complete project should always be unique
    default: {

    },
});


export const getID = atom({
    key: 'getID',
    default: 1
})