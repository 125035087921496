import React from "react";
import { Row, Col } from "antd";
import { ReactComponent as Fb } from "../assets/facebook.svg";
import { ReactComponent as Insta } from "../assets/instagram.svg";
const SocialBox = () => {
  return (
    <>
      <Row
        style={{
          marginTop: "12rem",
          background: "#59253a",
          maxWidth: "100%",
          paddingBottom: "12rem",
        }}
        align="middle"
        justify="end"
        gutter={20}
      >
        <Col span={8} xs={12} md={8}>
          <div className="socialMediaBox">
            <Row
              onClick={() =>
                window.open(
                  "https://www.facebook.com/My-Diet-Kart-106057178361035",
                  "_blank"
                )
              }
              justify="end"
            >
              <Fb
                style={{ cursor: "pointer" }}
                className="socialbox--icon"
                height={"5rem"}
              />
            </Row>
            <div style={{ padding: "5rem" }}>
              <h5
                style={{ fontSize: "4.375rem", lineHeight: "1rem" }}
                className="genderText"
              >
                14+
              </h5>
              <h5
                style={{
                  fontSize: "1.675rem",
                  lineHeight: "1.2rem",
                  marginRight: "2rem",
                }}
                className="genderText"
              >
                MILLION
              </h5>
              <h5
                style={{
                  fontSize: "1.675rem",
                  lineHeight: "1.2rem",
                  color: "hsla(0,0%,100%,.36)",
                  marginTop: "-.3rem",
                  marginRight: ".5rem",
                }}
                className="genderText"
              >
                Facebook
              </h5>
              <h5
                style={{
                  fontSize: "1.675rem",
                  lineHeight: "1.2rem",
                  color: "hsla(0,0%,100%,.36)",
                  marginTop: "-.3rem",
                  marginRight: "1rem",
                }}
                className="genderText"
              >
                Follower
              </h5>
            </div>
          </div>
        </Col>
        <Col span={8} xs={12} md={8}>
          <div className="socialMediaBox">
            <Row
              onClick={() =>
                window.open("https://www.Instagram.com/mydietkart", "_blank")
              }
              justify="end"
            >
              <Insta
                style={{ cursor: "pointer" }}
                className="socialbox--icon"
                height={"5rem"}
              />
            </Row>
            <div style={{ padding: "5rem" }}>
              <h5
                style={{ fontSize: "4.375rem", lineHeight: "1rem" }}
                className="genderText"
              >
                5+
              </h5>
              <h5
                style={{
                  fontSize: "1.675rem",
                  lineHeight: "1.2rem",
                  marginRight: "2rem",
                }}
                className="genderText"
              >
                MILLION
              </h5>
              <h5
                style={{
                  fontSize: "1.675rem",
                  lineHeight: "1.2rem",
                  color: "hsla(0,0%,100%,.36)",
                  marginTop: "-.3rem",
                }}
                className="genderText"
              >
                Instagram
              </h5>
              <h5
                style={{
                  fontSize: "1.675rem",
                  lineHeight: "1.2rem",
                  color: "hsla(0,0%,100%,.36)",
                  marginTop: "-.3rem",
                  marginRight: "1rem",
                }}
                className="genderText"
              >
                Follower
              </h5>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SocialBox;
