import React from "react";
import { Row, Col } from "antd";

// components
import DietTable from "./DietTable";
import DietToolbar from "./DietToolbar";

const DietCalendar = ({
  userProtien,
  userCarbs,
  userFat,
  dietChart,
  userInfo,
  summary,
}) => {
  const [day, setDay] = React.useState(1);
  const [week, setWeek] = React.useState("1");

  const prevBtn = () => {
    if (day === 1 && parseInt(week) === 1) {
      setDay((day) => day);
    } else {
      if (day === 1) {
        setDay(7);
        setWeek((week) => parseInt(week) - 1);
      } else {
        setDay((day) => day - 1);
      }
    }
  };

  const nextBtn = () => {
    if (day === 7) {
      // alert('Please select next week')
      setDay(1);
      setWeek((week) => parseInt(week) + 1);
    } else {
      setDay((day) => day + 1);
    }
  };
  return (
    <>
      <div className="container-fluid o-section-content">
        <Row className="justify-content-start text-white">
          <Col span={24}>
            <DietToolbar
              setDay={setDay}
              setWeek={setWeek}
              week={week}
              prevBtn={prevBtn}
              nextBtn={nextBtn}
            />
          </Col>
          <Col span={24}>
            <DietTable
              summary={summary}
              userInfo={userInfo}
              userProtien={userProtien}
              userCarbs={userCarbs}
              userFat={userFat}
              dietChart={dietChart}
              day={day}
              week={week}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DietCalendar;
