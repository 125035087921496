import React, { useState } from "react";

import { Row, Col, message, Popover } from "antd";

//components
import ProgressBar from "../../components/ProgressBar";
import Wrapper from "../../components/Wrapper";

import { useRecoilState } from "recoil";
import { userAtom } from "../../Recoil/Atoms/user";
// import Images from "../../assets/images.js";

import { ReactComponent as Chicken } from "../../assets/chicken.svg";
import { ReactComponent as Fish } from "../../assets/fish.svg";
import { ReactComponent as Eggs } from "../../assets/egg.svg";
import { ReactComponent as Mutton } from "../../assets/chop.svg";
import { ReactComponent as Nomeat } from "../../assets/flat.svg";
import { ReactComponent as ChickenSelected } from "../../assets/chickenselected.svg";
import { ReactComponent as FishSelected } from "../../assets/fishselected.svg";
import { ReactComponent as EggsSelected } from "../../assets/eggselected.svg";
import { ReactComponent as MuttonSelected } from "../../assets/chopselected.svg";
import { ReactComponent as NomeatSelected } from "../../assets/flatselected.svg";
import { ReactComponent as Right } from "../../assets/Icon awesome-check.svg";
import { ReactComponent as Wrong } from "../../assets/Icon metro-cross.svg";

let NOMEAT = "No Meat";
let LIST_NONVEG = [
  {
    id: 1,
    Title: "Chicken",
    icon: Chicken,
    selectedIcon: ChickenSelected,
    content: "Boneless recommended ",
  },
  {
    id: 2,
    Title: "Fish",
    icon: Fish,
    selectedIcon: FishSelected,
    content: "TRY TO HAVE FISH THAT MEAT IS SLIGHTLY RED OR RED,",
  },
  {
    id: 3,
    Title: "Egg",
    icon: Eggs,
    selectedIcon: EggsSelected,
    content: "-CONSUME WHOLE EGG [ NOT ONLY WHITE PART ]",
  },
  {
    id: 4,
    Title: "Mutton",
    icon: Mutton,
    selectedIcon: MuttonSelected,
    content: "Boneless recommended ",
  },
  {
    id: 5,
    Title: NOMEAT,
    icon: Nomeat,
    selectedIcon: NomeatSelected,
  },
];

const SelectGender = (props) => {
  const [selected, setSelected] = useState([]);
  const [userData, setUserData] = useRecoilState(userAtom);
  const [update, setupdater] = useState(false);

  const selectedMethod = (item) => {
    if (item.Title === NOMEAT) {
      setSelected([item.Title]);
      return;
    }
    if (selected.indexOf(item.Title) !== -1) {
      selected.splice(selected.indexOf(item.Title), 1);
      setSelected(selected);
      setupdater(!update);
    } else {
      if (selected.indexOf(NOMEAT) !== -1) {
        let tempSelected = selected;
        tempSelected.splice(tempSelected.indexOf(NOMEAT), 1);
        setSelected([...tempSelected, item.Title]);
      } else {
        setSelected([...selected, item.Title]);
      }
    }
  };
  const selectNonVeg = async () => {
    console.log("selected", selected);
    if (selected.length > 0 || selected.includes(NOMEAT)) {
      await setUserData({ ...userData, nonVeg: selected });
      props.history.push("/SGV");
    } else {
      message.error("Please select atleast One option");
    }
  };

  return (
    <Wrapper {...props}>
      <Row className={"select_row"} align={"top"} justify="start">
        <Col align="left" span={8} xs={24} md={8}>
          <div className="blockname">
            <div style={{ marginLeft: "10rem" }} className="blocknametext">
              Select
            </div>
            <div style={{ marginLeft: "8rem" }} className="blocknametext">
              Non-Veg
            </div>
          </div>
        </Col>
        <Col align="center" span={8} xs={24} md={8}>
          <Row
            style={{ marginTop: "3rem" }}
            gutter={10}
            align="middle"
            justify="center"
          >
            <Col>
              <Right className="icon-change" />
            </Col>
            <Col className="background--grey">
              <h4 className="heading">Include / Exclude</h4>
            </Col>
            <Col>
              <Wrong className="icon-change" />
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <h5 className="sub_heading background--grey">
              PLEASE CLICK TO INCLUDE PRODUCTS YOU LIKE:
            </h5>
          </Row>

          <Row gutter={20} align="middle" justify="center">
            {LIST_NONVEG.map((item, key) => {
              return (
                <Popover
                  content={item.content}
                  title={item.Title}
                  trigger="hover"
                >
                  <Col
                    onClick={() => selectedMethod(item)}
                    key={key}
                    align="center"
                    className={
                      selected.includes(item.Title)
                        ? "block__selected"
                        : "block"
                    }
                    span={8}
                  >
                    <Row
                      flex={1}
                      style={{ width: "100%" }}
                      align="middle"
                      justify="end"
                    >
                      {selected.includes(item.Title) ? (
                        <Right height="1rem" />
                      ) : (
                        <Wrong height="1rem" />
                      )}
                    </Row>
                    {selected.includes(item.Title) ? (
                      <item.selectedIcon height="5rem" />
                    ) : (
                      <item.icon height="5rem" />
                    )}
                    <h4 className="block_header">{item.Title}</h4>
                  </Col>
                </Popover>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <div onClick={() => selectNonVeg()} className="rowlabel">
          Continue
        </div>
      </Row>

      <ProgressBar value={54} />
    </Wrapper>
  );
};

export default SelectGender;
