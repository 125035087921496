const ScrollOne = require("./scrollerOne.png");
const ScrollTwo = require("./ScrollTwo.png");
const ScrollThree = require("./ScrollThree.png");
const Logo = require("./logo.svg");

export default {
    ScrollOne,
    ScrollTwo,
    ScrollThree,
    Logo
}