import React, { useEffect, useState } from "react";
// components
import Banner from "../../components/Banner";
import DietCalendar from "../../components/DietCalendar/index";
import Summary from "./Summary";
//actions called
import {
  getUserData,
  getUserDietChart,
  getProtienFoods,
  getCarbsFoods,
  getFatFoods,
} from "../../Actions/FirebaseAction";

const MyDietPlan = (props) => {
  const [userProtien, setUserProtein] = useState(null);
  const [userCarbs, setUserCarbs] = useState(null);
  const [userFat, setUserFat] = useState(null);
  const [dietChart, setDietChart] = useState(null);

  const [userInfo, setUserInfo] = useState({
    age: "",
    currentWeight: "",
    height: { ft: "", in: "" },
  });

  const [summary, setSummary] = useState({
    bmiInfo: { BMI: "", status: "" },
    age: "",
    water: "",
    targetWeight: "",
    isPaid: false,
  });
  //internal functions
  const _getUsersDatas = async (uid) => {
    let response = await getUserData(uid);
    // console.log("this is the complete response", response);
    await setUserInfo({
      age: response.age,
      currentWeight: response.currentWeight,
      height: response.height,
      gender: response.gender,
      // isPaid: response.isPaid || false,
      isPaid: true,
      email: response.email,
      phone: response.phone,
      physicalActivity: response.physicalActivity,
    });

    let BMIINFO = await _calculateBMI(
      response.height.ft,
      response.height.in,
      response.currentWeight
    );

    let water =
      response.physicalActivity === "Pro"
        ? "5.0L"
        : response.physicalActivity === "Beginner"
        ? "3.3L"
        : "4.0L";

    let Summary = {
      bmiInfo: BMIINFO,
      age: response.age,
      water: water,
      targetWeight: response.targetWeight,
    };
    // console.log("response", response);

    // let userFoodItems=[...response.fruits, ...response.dairy,...response.nonVeg,...response.veg,...response?.Fat];
    let userFoodItems = [
      ...response.fruits,
      ...response.dairy,
      ...response.nonVeg,
      ...response.veg,
      ...response?.Fat,
    ];

    // console.log("MAIN>>>",userFoodItems);
    setSummary(Summary);
    _getPlanDetails(response.physicalActivity, response.currentWeight);

    let userProtien = await getProtienFoods(userFoodItems);
    setUserProtein(userProtien);

    let userCarbs = await getCarbsFoods(userFoodItems);

    setUserCarbs(userCarbs);

    let userFats = await getFatFoods(userFoodItems);
    setUserFat(userFats);
  };

  const _getPlanDetails = async (type, weight) => {
    const response = await getUserDietChart(type, weight);
    console.log("response", response, summary, userInfo);
    let data = response;
    if (Number(summary.targetWeight) > Number(userInfo.currentWeight)) {
      console.log("inside the check");
      data.CARBS = response.CARBS + 80;
    }
    console.log("response", data);
    setDietChart(data);
  };

  const _calculateBMI = (feet, inch, weight) => {
    const MTR = 0.3048;
    const OVERWEIGHT = "Overweight";
    const HEAlTHY = "Healthy";
    const UNDERWEIGHT = "Underweight";

    let _heightMtr = feet * MTR + inch * MTR * 0.1;
    let BMI = (weight / (_heightMtr * _heightMtr)).toFixed(2);
    if (BMI > 25) {
      return { BMI, status: OVERWEIGHT };
    } else if (BMI <= 18) {
      return { BMI, status: UNDERWEIGHT };
    } else {
      return { BMI, status: HEAlTHY };
    }
  };

  useEffect(() => {
    const {
      match: { params: id },
    } = props;

    _getUsersDatas(id);
  }, []);

  return (
    <>
      <Banner />
      {userInfo.isPaid && (
        <DietCalendar
          userProtien={userProtien}
          userCarbs={userCarbs}
          dietChart={dietChart}
          userFat={userFat}
          userInfo={userInfo}
          summary={summary}
        />
      )}
      <Summary
        uid={props.match.params.id}
        userInfo={userInfo}
        summary={summary}
      />
    </>
  );
};

export default MyDietPlan;
