import React, { useState } from "react";

import { Row, Col } from "antd";
import ProgressBar from "../../components/ProgressBar";
import Wrapper from "../../components/Wrapper";
import { useRecoilState } from "recoil";
import { userAtom } from "../../Recoil/Atoms/user";
// import Images from "../../assets/images.js";

import { ReactComponent as Right } from "../../assets/Icon awesome-check.svg";
import { ReactComponent as Wrong } from "../../assets/Icon metro-cross.svg";

let NOMEAT = 'None Of The Above';
let LIST_VEG = [
  {
    id: 1,
    Title: 'I Do Not Get Enough Sleep',
  },
  {
    id: 2,
    Title: 'I Eat Late At Night',
  },
  {
    id: 3,
    Title: 'I Consume A Lot of Salt',
  },
  {
    id: 4,
    Title: 'I Cannot Give Up Eating Sweets',
  },
  {
    id: 5,
    Title: 'I Love Soft Drinks',
  },
  {
    id: 6,
    Title: NOMEAT,
  },
];

const SelectGender = (props) => {
  const [selected, setSelected] = useState([]);
  const [userData, setUserData] = useRecoilState(userAtom);
  const[update,setupdater] = useState(false)
  const selectedMethod = (item) => {
    if (item.Title === NOMEAT) {
      setSelected([item.Title]);
      return;
    }
    if (selected.indexOf(item.Title) !== -1) {
      console.log("inside the if we are having the item already ")
 
      selected.splice(selected.indexOf(item.Title), 1);
      console.log("TempSelected", selected);
      setSelected(selected);
      setupdater(!update)
    } else {
      console.log("inside the else")
      if (selected.indexOf(NOMEAT) !== -1) {
        let tempSelected = selected;
        tempSelected.splice(tempSelected.indexOf(NOMEAT), 1);
        console.log("tempSelected",tempSelected)
        setSelected([...tempSelected, item.Title]);
      } else {
        console.log("tempSelected >>",[...selected,item.Title])

        setSelected([...selected, item.Title]);
      }
    }
  };
  const selectNonVeg = async () => {
    if(selected.length>0){
    await setUserData({ ...userData,badHabits: selected });
      // props.history.push("/ID")
      props.history.push("/SGNV")
    }else{
      alert("please select atLeast one option")
    }
  };
  
  return (
   <Wrapper {...props}>

      <Row className={"select_row"} align={"top"} justify="start">
        <Col align="left" span={8} xs={24} md={8}>
          <div className="blockname">
            <div style={{ marginLeft: "10rem" }} className="blocknametext">
              Select
            </div>
            <div style={{ marginLeft: "8rem" }} className="blocknametext">
              habits
            </div>
          </div>
        </Col>
        <Col align="center" span={8} xs={24} md={8}>
          <Row  style={{marginTop:"3rem"}} gutter={10} align="middle" justify="center">
            <Col>
              <Right className="icon-change"/>
            </Col>
            <Col className="background--grey">
              <h4 className="heading">Include / Exclude</h4>
            </Col>
            <Col>
              <Wrong className="icon-change" />
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <h5 className="sub_heading background--grey">
              PLEASE CLICK TO INCLUDE PRODUCTS YOU LIKE:
            </h5>
          </Row>

          <Row gutter={20} align="middle" justify="center">
            {LIST_VEG.map((item, key) => {
              return (
                <Col onClick={()=>selectedMethod(item)}  key={key} align="center" className={selected.includes(item.Title)?"block__selected":"block"} span={8}>
                  <Row
                    flex={1}
                    style={{ width: "100%" }}
                    align="middle"
                    justify="end"
                  >
                  <h4 className="block_header">{item.Title}</h4>
                    {selected.includes(item.Title) ? (
                      <Right height="1rem" />
                    ) : (
                      <Wrong height="1rem" />
                    )}
                  </Row>
                
                
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row align="middle" justify="center">
      <div onClick={()=>selectNonVeg()} className="rowlabel">Continue</div>
      </Row>
    
    <ProgressBar value={45} />
    </Wrapper>
  );
};

export default SelectGender;
