import React from "react";
import { Row, Col, Typography } from "antd";
import { ReactComponent as Logo } from "../../assets/NEWLogo.svg";
const Aboutus = (props) => {
  const { Paragraph } = Typography;
  return (
    <section style={{ padding: "2rem" }}>
      <Row style={{ minWidth: "100%" }} align="middle" justify="center">
        <Col align="center" style={{ padding: "2rem" }} span={22}>
          <Logo />
        </Col>
      </Row>
      <Row className="">
        <Col span={24} align="center">
          <h1 style={{ padding: "2rem", color: "white" }}>About Us.</h1>
          <img
            style={{
              marginBottom: "2rem",
              maxWidth: "100%",
              objectFit: "cover",
            }}
            src={
              "https://images.unsplash.com/photo-1554998171-89445e31c52b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=3510&q=80"
            }
          />
          <h3 style={{ color: "white" }}>
            No strict nutrition philosophies, staying unrealistically thin, or
            depriving yourself of the foods you love. “Eat what you can sustain
            and easily integrate it into your lifestyle.” Feel great, have more
            energy, build your stamina and immunity – all of which can be
            achieved by learning some nutrition basics and incorporating them in
            a way that works for you.” My Diet Kart (MDK) has team of dietitian
            and nutritionist with a very clear vision to create diets plans
            which are easy to follow and are guaranteed effective. The science
            behind the food is very well known to the team, Each food item has
            its own content and our team of expert nutritionist creates the diet
            plan by analyzing the food that you take on daily basis with your
            age ,height and weight,Making sure that you get what is important
            for you and at the same time assuring it helps you loose weight. We
            know food products which are not part of our daily life are always
            temporary changes and couldn’t be part of our life style for long
            run, We create the diet plans which can be included and easily
            adopted.
          </h3>
        </Col>
      </Row>
      <Row className="">
        <Col span={24} align="center">
          <h1 style={{ padding: "2rem", color: "white" }}>Features</h1>
          <img
            style={{
              marginBottom: "2rem",
              maxWidth: "100%",
              objectFit: "cover",
            }}
            src={
              "https://images.unsplash.com/photo-1511467687858-23d96c32e4ae?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80"
            }
          />

          <h2 style={{ color: "white" }}>
            DashBoard - get a complete information dashboard to track progress
          </h2>
          <h2 style={{ color: "white" }}>Complete analysis of your diet</h2>
          <h2 style={{ color: "white" }}>Fully Customizable Diet Plan</h2>
          <h2 style={{ color: "white" }}>7 week guaranteed weight loss plan</h2>
          <h2 style={{ color: "white" }}>Support 24/7</h2>
        </Col>
      </Row>
      <Row style={{background:"black"}}>
        <Col span={24} align="center">
          <h1 style={{ padding: "2rem", color: "white" }}>Contact Us</h1>

          <h3 style={{ color: "white" }}>Email: Support@mydietcart.com</h3>
          <h3 style={{ color: "white" }}>
            Address: c/o SPACE 42-C (2nd floor) Basant Vihar (Opp Totall
            Hospital) Vijay Nagar Indore 452010
          </h3>
          <h3 style={{ color: "white" }}>Ph: 0731-3550138</h3>
        </Col>
      </Row>
    </section>
  );
};

export default Aboutus;
