import React from 'react'
// import {Row} from 'antd'
// import { ReactComponent as Fb } from "../assets/facebook.svg";

const SummaryCards = ({data}) => {
    return (
        <>
            <div className="summary-item">
                <div className="m-summary">
                    <div className="m-summary__content">
                        <div className="m-summary__title">{data.title}</div>
                        <div className="svg-graph-1">
                            <div className="svg-graph-1__info">
                                <figure>
                                    <img className="summary-card-img" src={data.image} alt={data.title} />
                                </figure>
                                {/* <div className="svg-graph-1__title" style={{ color: data.color }}>
                                    <div className="svg-graph-1__title-number">25,</div>
                                    <div className="svg-graph-1__title-sup">90</div>
                                </div> */}
                                {/* <div className="svg-graph-1__subtitle">{data.subtitle}</div> */}
                            </div>                                         
                        </div>
                        <div className="m-summary__subtitle">
                            <span>{data.subtitle} </span>
                            <span style={{ color: data.color }}>
                                {data.content}
                            </span>                            
                        </div>
                    </div>                    
                </div>
            </div>
        </>
    )
}

export default SummaryCards
