import React from 'react'
import { Row, Col } from "antd";

const Footer = (props) => {
    return (
        <>
        <footer>
            <Row
                style={{ background: "#59253a", minHeight: "10rem" }}
                flex={1}
                justify="space-between"
                align="middle"
            >
                <Col align="center" span={6} xs={12} md={6}>
                    <div className="footer-mention">
                        <div>2021 ALL RIGHTS RESERVED</div>
                        <div>
                            Developed by{" "}
                            <span style={{ fontWeight: "700", color: "#fff" }}>
                                CODEWITHVISH
              </span>
                        </div>
                    </div>
                </Col>
                <Col align="center" span={6} xs={12} md={6}>
                    <div className="footer-heading-Text">My Diet Kart</div>
                </Col>
                <Col span={8} xs={24} md={10}>
                    <Row align="middle" justify="center" className="faqSection">
                        <Col align="center" span={2}>
                            <a onClick={()=>props.history.push("/policy/privacy_policy")} className="footer-menuItem">Privacy Policy</a>
                        </Col>
                        <Col align="center" span={8}>
                            <a onClick={()=>props.history.push("/policy/Terms")} className="footer-menuItem">Terms & Conditions</a>
                        </Col>
                        <Col align="center" span={8}>
                            <a onClick={()=>props.history.push("/policy/Refund")} className="footer-menuItem">Cancellation/Refund Policy</a>
                        </Col>
                        <Col align="center" span={6}>
                            <a className="footer-menuItem">Cookie policy</a>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row
                style={{ background: "#59253a", minHeight: "8rem" }}
                flex={1}
                justify="space-between"
                align="middle"
            ></Row>
        </footer>
        </>
    )
}

export default Footer