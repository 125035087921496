import React from 'react'
import { Row, Col } from "antd";

const Banner = () => {
    return (
        <>
                <Row className={"select_row"} align={"top"} justify="center">
                    {/* <Col align="left" span={8} xs={24} md={8}>
                        <div className="blockname">
                            <div style={{ marginLeft: "10rem" }} className="blocknametext">
                                SELECT
            </div>
                            <div style={{ marginLeft: "8rem" }} className="blocknametext">
                                YOUR
            </div>
                            <div style={{ marginLeft: "5.8rem" }} className="blocknametext">
                                GENDER
            </div>
                        </div>
                    </Col> */}
                    <Col align="center">
                        <Row>
                            <div className="blockname blocknametext" >PERSONALIZED MEAL PLAN</div>
                        </Row>
                    </Col>
                </Row>
        </>
    )
}

export default Banner
