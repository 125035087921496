import React from "react";
import { Row, Col } from "antd";
import "react-step-progress-bar/styles.css";

import { useRecoilState } from "recoil";
import { userAtom } from "../../Recoil/Atoms/user";
// import Images from "../../assets/images.js";
import { ReactComponent as Man } from "../../assets/man-running.svg";
import { ReactComponent as Woman } from "../../assets/woman-running.svg";

// components
import Wrapper from "../../components/Wrapper";
import ProgressBar from "../../components/ProgressBar";
const MALE = "male";
const FEMALE = "female";
const SelectGender = (props) => {
  const [userData, setUserData] = useRecoilState(userAtom);
  const SelectGender = async (gender) => {
    await setUserData({ ...userData, gender: gender });
    props.history.push("/SPA");
  };
  return (
    <>
      <Wrapper {...props}>
        <Row className={"select_row"} align={"top"} justify="start">
          <Col align="left" span={8} xs={24} md={8}>
            <div className="blockname">
              <div style={{ marginLeft: "10rem" }} className="blocknametext">
                SELECT
              </div>
              <div style={{ marginLeft: "8rem" }} className="blocknametext">
                YOUR
              </div>
              <div style={{ marginLeft: "5.8rem" }} className="blocknametext">
                GENDER
              </div>
            </div>
          </Col>
          <Col align="center" span={8} xs={24} md={8}>
            <Row align="middle" className="maleFemaleContainer">
              <Col span={10} xs={12} md={10}>
                <div onClick={() => SelectGender(MALE)} className="genderBox">
                  <Man height="12rem" />
                  <h4 className="genderText">Male</h4>
                </div>
              </Col>
              <Col span={4} xs={0} md={4}>
                <h4 className="genderOR">OR</h4>
              </Col>
              <Col span={10} xs={12} md={10}>
                <div
                  onClick={() => SelectGender(FEMALE)}
                  className="genderBoxWomen"
                >
                  <Woman height="12rem" />
                  <h4 className="genderText">Female</h4>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <ProgressBar value={10} />
      </Wrapper>
    </>
  );
};

export default SelectGender;
