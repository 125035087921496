import React from "react";
import { Row, Col } from "antd";
// assets
import { ReactComponent as Logo } from "../assets/NEWLogo.svg";

const Header = (props) => {
  const { history } = props;
  return (
    <>
      <Row className="HeaderRow " align="middle" justify="start">
        <Col style={{ padding: "1rem" }} span={10}>
          <div>
            <Logo height={"10rem"} style={{ zIndex: 1000 }} />
          </div>
        </Col>
        <Col style={{ padding: "1rem" }} align="end" span={12}>
          <div onClick={() => history.push("/aboutus")}>
            <h5 className="menuItem">About Us</h5>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Header;
