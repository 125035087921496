import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
// css
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./App.css";
import "./index.css";
// import "./main.scss";
import "./assets/scss/style.scss";
import { BrowserRouter as Router } from "react-router-dom";
import RouterComponent from "./Router";
import reportWebVitals from "./reportWebVitals";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <RouterComponent />
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
