import React from 'react'
import ColumnItemList from '../ColumnItemList/ColumnItemList'

export const AllCarbsData = ({ carbsData, orLength }) => {
    return (
        <>
            {carbsData.map((c, i) => {
                // orShown = column === 2 ? carbsData.length - (carbsData.length === 1 ? 1 : 2) !== i : false
                return (
                    <>
                        <ColumnItemList c={c} />

                        { carbsData.length - orLength !== i && (
                            <li className="list-group-item meal-item__list text-center bold">
                                OR
                            </li>
                        )}
                    </>
                )
            })}
        </>
    )
}

export const WithoutFruitsCarbsData = ({ carbsData, column }) => {
    return (
        <>
            {carbsData.map((c, i) => {
                let orShown = column === '2' ? carbsData.length - (carbsData.length === 1 ? 1 : 1) !== i : column === '4' ? i < carbsData.length - 1 : false
                return (
                    <>
                        <ColumnItemList c={c} />
                        { orShown && (
                            <li className="list-group-item meal-item__list text-center bold">
                                OR
                            </li>
                        )}
                    </>
                )
            })}
        </>
    )
}

export default { AllCarbsData, WithoutFruitsCarbsData }
