import * as Firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAHs0e0FwaKSxVXPLyACFSJf_rdKZ0ZZXY",
  authDomain: "mydietcart-9cdbc.firebaseapp.com",
  projectId: "mydietcart-9cdbc",
  storageBucket: "mydietcart-9cdbc.appspot.com",
  messagingSenderId: "160954216927",
  appId: "1:160954216927:web:84fec0ae37c635e245d46a",
  measurementId: "G-J2BF6DKN5F",
};
const FIREBASEINIT = Firebase.default.initializeApp(firebaseConfig);

const FirebaseAuth = Firebase.default.auth();
const FirebaseDB = Firebase.default.firestore().collection("Users");
const FirebaseDBDeitCharts = Firebase.default
  .firestore()
  .collection("DietChart");
const FirebaseDBFood = Firebase.default.firestore().collection("FOOD");
const FirebaseDBPayment = Firebase.default.firestore().collection("Payments");
const FirebaseFunctions = Firebase.default.functions(); //!remove this before deployment

export const registerWithEmail = async ({ email, password, userInfo }) => {
  try {
    const response = await FirebaseAuth.createUserWithEmailAndPassword(
      email,
      password
    );
    await addUserInfo(response.user.uid, userInfo);
    return {
      success: true,
      message: "Registration Successful",
      userID: response.user.uid,
    };
  } catch (error) {
    return { success: false, message: `Something went wrong ${error}` };
  }
};

///Database Method
const addUserInfo = async (uid, userInfo) => {
  try {
    // console.log("userInfo", userInfo);
    let response = await FirebaseDB.doc(uid).set(userInfo);
    // console.log("response", response);
  } catch (error) {
    console.error(error);
  }
};

export const getUserData = async (uid) => {
  // console.log("firebase", FIREBASEINIT);
  // console.log("userid", uid);
  try {
    let response = await FirebaseDB.doc(uid.id).get();
    if (response.exists) {
      console.log("User data: ", response.data());
      return response.data();
    }
  } catch (error) {
    console.error("error", error);
    return Error("Failed to fetch the user data please try again");
  }
};

export const getUserDietChart = async (type, weight) => {
  let uppercase = type.toUpperCase();
  // console.log("check this our", uppercase)
  try {
    let response = await FirebaseDBDeitCharts.doc(uppercase)
      .collection("CHARTONE")
      .where("WEIGHTMIN", "<=", Number(weight))
      .orderBy("WEIGHTMIN", "desc")
      .get();
    console.log("response from getuser", response);
    if (!response.empty) {
      // console.log("getUserDietChart", response.docs[0].data());
      return response.docs[0].data();
    }
  } catch (error) {
    console.log("error", error);
    return Error("Failed to fetch the user data please try again");
  }
};

export const getProtienFoods = async (items) => {
  try {
    let response = await FirebaseDBFood.doc("PROTIEN").get();
    let allProtein = response.data();
    let userProtein = [];
    items.map((item) => {
      console.log("item>>", item.split(" ").join(""));

      if (allProtein.hasOwnProperty(item.split(" ").join("").toUpperCase())) {
        userProtein.push({
          [item.split(" ").join("").toUpperCase()]: allProtein[
            item.split(" ").join("").toUpperCase()
          ],
        });
      }
    });
    // console.log("protien Food",userProtein)
    return userProtein;
  } catch (error) {
    console.log(error);
  }
};

export const getCarbsFoods = async (items) => {
  try {
    let response = await FirebaseDBFood.doc("CARBS").get();
    let allProtein = response.data();
    let userCarbs = [];
    items.map((item) => {
      if (allProtein.hasOwnProperty(item.split(" ").join("").toUpperCase())) {
        userCarbs.push({
          [item.split(" ").join("").toUpperCase()]: allProtein[
            item.split(" ").join("").toUpperCase()
          ],
        });
      }
    });
    // console.log("Carbs Food",userCarbs)

    return userCarbs;
  } catch (error) {
    console.log(error);
  }
};

export const getFatFoods = async (items) => {
  try {
    let response = await FirebaseDBFood.doc("FAT").get();
    let allProtein = response.data();
    let userCarbs = [];
    items.map((item) => {
      if (allProtein.hasOwnProperty(item.split(" ").join("").toUpperCase())) {
        userCarbs.push({
          [item.split(" ").join("").toUpperCase()]: allProtein[
            item.split(" ").join("").toUpperCase()
          ],
        });
      }
    });
    // console.log("Carbs Food",userCarbs)

    return userCarbs;
  } catch (error) {
    console.log(error);
  }
};

export const UpdatePaymentForUser = async (uid, RazorPayData) => {
  try {
    let response = await FirebaseDB.doc(uid).update({
      isPaid: true,
      RazorPayDetails: RazorPayData,
    });
    let responsePayment = await FirebaseDBPayment.doc(uid).set({
      RazorPayData,
    });
    return { status: true };
  } catch (error) {
    console.log(error);
    return { status: false };
  }
};

export const firebaseBaseFunctions = async () => {
  const payfun = await FirebaseFunctions.httpsCallable("PaymentOrder");

  try {
    let response = await payfun({ name: "vishesh" });
    if (response.data.success) {
      return response.data.order;
    }
  } catch (error) {
    console.log("this is the error", error);
  }
};
export const sendEmail = async (uid, dest) => {
  const emailInstance = await FirebaseFunctions.httpsCallable("sendEmail");
  try {
    let response = await emailInstance({ uid, dest });
    console.log("response from email", response);
  } catch (error) {
    console.log("this is the error", error);
  }
};
