
import { Component } from 'react';

export default class LikeButton extends Component {
    constructor(props) {
    super(props);
    this.state = {
        counter:100,
        isLiked:false
    };
  }
    buttonClick =()=>{
       
            if(this.state.isLiked===true){
                this.setState({
                    counter:this.state.counter-1,
                    isLiked:false
                })
            }else{
                this.setState({
                    counter:this.state.counter+1,
                    isLiked:true
                })
            }
        }
    render() {
        const{counter,isLiked}=this.state;
       
        return (
            <>
                <div  className={`like-button ${isLiked?'liked':''}`}>
                {isLiked ?<h2 onClick={()=>this.buttonClick()}>Liked</h2>: <h2 onClick={()=>this.buttonClick()}>Like | <span className='likes-counter'>{counter}</span></h2>}
                </div>
                <style>{`
                    .like-button {
                        font-size: 1rem;
                        padding: 5px 10px;
                        color:  #585858;
                    }
                   .liked {
                        font-weight: bold;
                        color: #1565c0;
                   }
                `}</style>
            </>
        );
    }
}
