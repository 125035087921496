import React from 'react'
// components
import Header from './Header'
import Footer from './Footer'
import SocialBox from "./SocialBox";
const Wrapper = ({children,...rest}) => {
    return (
        <>
            <div className="main_container">
                <Header {...rest} />
                    {children}
                    <SocialBox/>
                <Footer {...rest} />
            </div>
        </>
    )
}

export default Wrapper
