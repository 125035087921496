import React from "react";
import { Row, Col } from "antd";
import "react-step-progress-bar/styles.css";
import { useRecoilState } from "recoil";
import Wrapper from "../../components/Wrapper";
import ProgressBar from "../../components/ProgressBar";
import { userAtom } from "../../Recoil/Atoms/user";
// import Images from "../../assets/images.js";

let LIST = [
  {
    id: 1,
    Title: "Almost No Physical Activity",
    Type: "Beginner",
  },
  {
    id: 2,
    Title: "I Often Go For A Walk",
    Type: "Beginner",
  },
  {
    id: 3,
    Title: "I Exercise 1-2 Times a Week",
    Type: "intermediate",
  },
  {
    id: 4,
    Title: "I Exercise 3-5 Times a Week",
    Type: "Pro",
  },
  {
    id: 5,
    Title: "I Exercise 5-7 Times a Week",
    Type: "Pro",
  },
];
const SelectGender = (props) => {
  const [userData, setUserData] = useRecoilState(userAtom);
  const selectActivity = async (value) => {
    await setUserData({ ...userData, physicalActivity: value });
    // props.history.push("/SGNV");
    props.history.push("/STD");
  };
  return (
    <Wrapper {...props}> 
      <Row className={"select_row"} align={"top"} justify="start">
        <Col align="left" span={8} xs={24} md={8}>
          <div className="blockname">
            <div style={{ marginLeft: "10rem" }} className="blocknametext">
              Physical
            </div>
            <div style={{ marginLeft: "8rem" }} className="blocknametext">
              Activity
            </div>
          </div>
        </Col>
        <Col align="center" span={8} xs={24} md={8}>
          {LIST.map((item, key) => {
            return (
              <div
                onClick={() => selectActivity(item.Type)}
                key={key}
                className="rowlabel"
              >
                {item.Title}
              </div>
            );
          })}
        </Col>
      </Row>

      <ProgressBar value={22} />
    </Wrapper>
  );
};

export default SelectGender;
